// import { googleOauth } from "./oauth-google.service.js";
export default {
  data: function() {
    return {};
  },
  mounted: function() {
    var url = new URL(window.location.href);
    var code = url.searchParams.get("code");
    localStorage.setItem("codeGoogle", code);
  }
};
